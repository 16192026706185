$white: #fff;
$footer-border-top: #005F83;
$footer-bg: #e8e8e8;
$footer-text: #777;
$footer-link: #40B8E7;
$header-bg: #3C4B56;
$active-link: #00A0DF;
$subnav-active: #425563;
$table-th: #404040;
$subnav-heading: #333333;
$button-border: #037DAE;
$table-record-border: #D8D8D8;
$textarea-label-color: #98A4AE;
$font-family-regular: CharlesModern-Regular, Arial, Helvetica, sans-serif;
$font-family-bold: CharlesModern-Bold, Arial, Helvetica, sans-serif;
$font-small: 12px;
$font-normal: 16px;
$font-bold: 18px;
$table-record-background: #F7F7F7;
$border-color: #a6a6a6;
$black: #000;
$font-2x: 20px;
$font-3x: 25px;
$font-4x: 32px;
$dropdown-icon-color: #666;
$error-field-border: #a80000;
$activate-button-color: #7A9C49;
$tab-background: #E7E7E7;
$disabled-color: #ccc;
$create-rule-color: #B95E04;
$expired-rule-color: #BF5549;
$draft-rule-color: #E8AB3B;
$error-icon-color: #a03;
$warning-border-color: #f7df8c;
$tree-table-subheader: #026D97;
$security-header: #f3f0f0;
$iconColor: #007ad9;
$error-icon: #E70D18;
$access-error-color: #aa0033;
$override-background-color:#9CAF86;
$sps-background-color:#F9A03F;
$attempt-trade-background-color:#BF5549;
$green-background-color:#579228;
$dropdown-data-color: #e6f3f8;
$textarea-lbl: #007ad9;
$workbench-hold-status:#1A5F8A;
$workbench-validation-mismatch:#40B8E7;
$workbench-indexing:#FF5722;
$primeng-input-boxshadow: #8dcdff;
$ActionMenu-li-hover-color: d0ecf7;
$client-name-indicator: #00aa93;
$table-even-row-background: #f9f9f9;
$calendar-button-background-hover: #116fbf;
