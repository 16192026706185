/*Customized primeng Accordion Component CSS*/
@import 'global';

.accordion-panel {
    margin-top: -1px;

    .emtr-accordion {
        outline: none;
        pointer-events: auto;

        .sdps-accordion__section {
          padding: 10px 13px;
        }

        .sdps-accordion__section-header-container.sdps-button {
          pointer-events: none;
          height: 100%;
        }

        .sdps-accordion__section-title {
          padding: 0px;
            .custom-title {
                padding-left: 10px;
                vertical-align: sub;
            }

            .sdps-button {
                border: 2px solid $button-border;
                color: $button-border;
                width: 30px;
                height: 30px;
                line-height: 30px;
                margin: 10px 0 0;
                @include border-radius(0px);
                position: relative;
            }

            .sch-chevron-down:before {
                content: "\e63f";
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform-translate(-50%, -50%);
            }

            .sch-chevron-right:before {
                content: "\e63e";
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform-translate(-50%, -50%);
            }
        }
      .sdps-accordion__section-action-btn {
        display: none;
      }

      .sdps-accordion__section-body {
        padding-top: 15px;
        padding-left: 4px;
        padding-bottom: 0px;
      }

      .sdps-accordion__section-header:hover {
        background-color: transparent;
      }

      .sdps-button{
        &:focus{
          outline: 0;
        }
      }
    }
}

.edit-button {
  pointer-events: auto;
    .pi {
        font-size: 1em;
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform-translate(-50%, -50%);
    }

    &.sdps-button {
        border: 1px solid $button-border;
        background: $white !important;
        color: $button-border !important;
        width: 30px;
        padding: 4px 3px;
        @include border-radius(0px);
    }

    &:hover {
        background-color: $button-border !important;
        color: $white !important;
    }

    &.edit-button-of-client {
      .pi {
        position: relative;
      }
    }
}

.p-progress-spinner-svg {
  height: 50%;
  width: 50%;
  margin: 0;
  top: -12px;
  left: -2px;
}
.spinnerStyle {
  position: absolute !important;
  width: 50px !important;
  height: 50px !important;
  .p-progress-spinner {
    width: 50px !important;
    position: absolute;
    height: 50px !important;
  }
}
.p-progress-spinner-circle {
  stroke: $button-border !important;
}

