@import 'variable';
@import 'global';

body {
  background: $white;
}

.icon-border {
  border: 1px solid $button-border;
  padding: 2px;
}

.divider {
  border-right: 1px solid $border-color;
  height: 44px;
}

.centered-container {
  display: flex;
  align-items: center;
}

.customize-view-text {
  font-size: 18px;
  color: $button-border;
}

.expanded-row {
  display: flex;
  flex-flow: row wrap;
  border: 0px solid #DDD;

  .expanded-row-item {
    width: 20%;

    .er-item-header {
      background: $white;
      border-bottom: 1px solid #DDD;
      padding: 12px;
      font-size: 14px;
      font-weight: bold;
    }

    .er-item-data {
      font-size: 14px;
      padding: 12px;
      background: #f9f9f9;
    }
  }
}

.master-type {
  padding: 4px 12px;
  background: $button-border;
  @include border-radius(12px);
  color: $white;
}

.action-bar {
  background: $table-record-background;
  justify-content: space-between;
}
