@import "global";

.addlink-chip{
    @include border-radius(30px);
    padding: 4px 12px;
    color:#FFF;
}

.addlink-chip_client-name,  .addlink-chip_link-type-standard{
    background: #98a4ae;
}

.addlink-chip_link-type-multi{
    background: #944e76;
}

.addlink-action-panel{
    background:#f7f7f7;
}

.addlink-hide-overflow{
    overflow: hidden;
}
/* showing employeeName and acctHolderName as per screen resolution */
.employeeFirstNameWidth {
  display: block;
  max-width: 160px !important;
}
.employeeNameWidth {
  max-width: 160px !important;
}

.client-selection-dropdown {
  .p-dropdown {
    width: 276px;
  }

  .display-client-text {
    width: auto !important;
  }
}

.common-truncate--ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 197px;
}

@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .employeeNameWidth {
    max-width: 150px !important;
  }
  .master-account-width {
    max-width: 55%;
    display: block;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .employeeNameWidth {
    max-width: 129px !important;
  }

  .master-account-width {
    max-width: 66%;
    display: block;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1400px) {
  .employeeNameWidth {
    max-width: 121px !important;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .employeeNameWidth {
    max-width: 106px !important;
  }

  .master-account-width {
    max-width: 85%;
    display: block;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1348px) {
   .client-selection-dropdown {
    .p-dropdown {
      width: 247px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .employeeNameWidth {
    max-width: 101px !important;
  }
}


@media screen and (min-width: 1700px) {
  .master-account-width {
    max-width: 52%;
    display: block;
  }
}
