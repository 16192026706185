@import "variable";

.p-multiselect {
  width: 100%;
  border-radius: 0;
    &.p-state-default {
        height: 35px;
    }
}

.p-element-header {
    border-radius: none !important;
}

.p-multiselect-panel {
   /* &.p-corner-all {
        border-radius: 0;
    }*/

    .p-multiselect-header {
        .p-checkbox {
            float: right !important;
            margin-right: 0 !important;
         /* .p-checkbox-box {
            position: absolute;
            top: 50%;
            transform: translate(0px, -50%);
          }*/
        }
    }
}

.p-multiselect-filter-icon  {
    display: none !important;
}

.p-multiselect-close {
    display: none !important;
}

.status-value {
    .p-multiselect-header {
        .p-checkbox {
            margin-right: 17px !important;
            margin-top: 8px;
            position: relative;
        }
    }
}
.request-list-filter {
    .p-float-label input {
        border: 1px solid $border-color !important;
        height: 100% !important;
    }
    .p-float-label input:focus {
        border-color: $textarea-lbl !important;
        box-shadow: 0 0 0 0.2em $primeng-input-boxshadow !important;
    }
    .p-disabled {
        background: $border-color;
    }
    .p-multiselect {
        &.p-disabled{
            .p-multiselect-trigger{
                background: $border-color;
            }
        }
    }
  &.request-list-filter-multi-dropdown {
    .p-multiselect {
      width: 65%;
      height: 35px;
    }
  }

  &.create-user-roles-multi-dropdown {
    .p-multiselect {
      width: 70%;
      height: 35px;
      .p-highlight {
        .p-checkbox-box {
          border: transparent;
        }
      }
    }
  }

  &.request-list-filter-multiselect {
    .p-multiselect-panel {
      width: 145% !important;
      .p-multiselect-header {
        .p-checkbox-box {
          transform: translate(-75%, -50%) !important;
        }
      }
    }
  }

  &.request-list-multiselect-font-size {
    .p-multiselect {
      width: 100%;
      height: 35px;
      border-radius: 0;
      min-width: 10.5rem;
      margin: 0 0 5px;
            .p-multiselect-label {
        font-size: 14px !important;
        font-weight: initial;
        height: 30px;
        padding: 0.329rem;
        }
      }
    }
  
  &.addlink-report-multi-dropdown {
    .p-multiselect {
      padding: 1rem;
      // min-width: 12.5em;

      .p-multiselect-label {
        vertical-align: middle;
        padding: 0;
        color: #000;
        font-size: 16px;
      }
    }
  }
}
.timePickerForm .p-disabled {
  background: $border-color;
  }
.p-dropdown {
   &.p-disabled {
     .p-dropdown-trigger {
       background: $border-color;
     }
     .p-dropdown-label {
       background: $border-color;
     }
   }
 }

.timePickerForm .p-disabled .pi-chevron-down {
  color: #000;
  font-size: 22px !important;
}

.filtered-table {
  .p-table-scrollable-body {
    z-index: 0;
  }

  .p-element-header {
    overflow: unset !important;
  }

  .request-list-filter-multiselect {
    .p-multiselect {
      &.p-default {
        margin: 0 0 5px;
      }
    }
  }
}

.timePickerForm {
  .p-dropdown .p-dropdown-label {
    font-size: 14px;
    padding: 0.329rem;
    font-family: $font-family-regular;
  }
}

.select-report-type {
  .timePickerForm .p-dropdown {
    padding: 0.7rem;
  }
}
.filtered-row-input {
  .p-dropdown {
    min-width: 12.5rem;
  } 
}