@import "variable";
@import 'global';

.adl-modal__heading{
    //font-size: 17px;
    //font-size: 1.0625rem;
    //line-height: 22px;
    //line-height: 1.375rem;
    //color: #111;
    //font-family: "CharlesModern-Regular", "Charles Modern", Arial, Helvetica, sans-serif;
    //margin: 0;
    //margin-bottom:12px;
    //padding: 0;
}

.adl-modal__body{
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 20px;
    line-height: 1.25rem;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin: 0;
    padding: 0;
}

.adl-textarea {
  width: 100%;
  height: 64px;
  border: 1px solid #ccc;
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: scroll;
  outline: none;
  &:focus{
    border: 1px solid $textarea-lbl !important;
  }
}

.adl-ui-dropdown{
  padding:2px 12px !important;
  @include border-radius(0px);
}

.adl-ui-dropdown-panel{
  @include border-radius(0px);
}
.adl-ui-dropdown-item{
  padding:6px 12px !important;
}

.adl-form-input{
  padding: 8px;
  display: block;
  border: 1px solid #ccc;
}

.adl-checkbox-none{
    margin: initial !important;
    display:inline-block !important;
}

.adl-checkbox{
  margin: auto;
  margin-top:4px;
  width: 28px;
  display:block;

  &.ng-dirty.ng-invalid {
      .p-chkbox-box {
          border: 1px solid  $border-color !important;
      }
  }

  .adl-ui-chkbox{
        width:28px !important;
        height:28px !important;
      .p-chkbox-box {
          width:28px !important;
          height:28px !important;
          @include border-radius(0px);
          border: 1px solid $border-color !important;

          &:not(.ui-state-disabled) {
              &:hover {
                  border-color: $border-color;
              }
          }

          &.p-highlight {
              border-color: $button-border;
              background-color: $button-border !important;
          }

          &.p-highlight:not(.p-state-disabled) {
              &:hover {
                  border-color: $button-border;
                  background-color: $button-border;
              }
          }

          &.ui-state-focus {
              box-shadow: none;
              border-color: $border-color;
          }

          .pi{
            line-height: 1.5 !important;
          }

      }
  }

  .ui-chkbox-label {
      font-size: $font-normal;
      top:4px;
  }

  &.on-check {
      color: $border-color;
  }

  &.on-uncheck {
      color: $black;
  }

}
