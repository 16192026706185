@import "variable";

.security-type-holder {
    padding-left: 40px;

    .choose-security-box {
        width: 272px;
        height: 45px;
        background-color: $button-border;
        color: $security-header;
        cursor: pointer;

        .security-box-content {
            font-size: 25px;
            text-align: center;
            vertical-align: middle;
            line-height: 44px;
            padding: 0 0 0 8px;
        }
    }

    .security-highlight {
        color: $activate-button-color;
        position: absolute;
        top: 1px;
        left: 0;
    }

  .assetGroup-highlight {
    color: $activate-button-color;
    position: relative;
    top: 1px;
    left: 0;
  }

  .security-level-text {
    position: absolute;
    left: 20px;
    font-weight: bold;
  }

    .security-type-text {
        width: 250px;
        position: relative;
    }
}
