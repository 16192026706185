@import "global";

.emtr-datepicker {
  .p-calendar {
    width: 100%;
    button {
      font-size: 18px;
      float: right;
    }
    &.p-calendar-w-btn {
      .p-inputtext {
        border: none;
      }
    }
    .p-datepicker-trigger.p-button {
      background: none;
      border: none;
      color: $footer-border-top;
    }
    .p-button:enabled:focus{
      box-shadow: none;
    }
  }
}

.emtr-calender {
  border: 1px solid $footer-bg;
  display: inline-block;
  width: 100%;
  .p-inputtext {
    border: none !important;
    padding-top: 14px;
  }

  .p-float-label {
    .labelDisabled {
      opacity: 0.5;
    }

    .p-inputwrapper-filled~label,
    .p-inputwrapper-focus~label {
      top: 0.6em;
    }
  }

  &.request-list-filter-calender {
    background: $white;

    .p-calendar .p-datepicker {
      min-width: 100%;
      width: 280px !important;
    }
  }
}

.date-errorHighlight{
  border-color: $error-field-border !important;
}

.date-label-errorHighlight{
  color: $error-field-border !important;
}

.timePickerForm {
  .pi-chevron-down {
    font-family: 'Schwab-Icon-Font', sans-serif !important;
    font-size: $font-2x !important;
    color: $dropdown-icon-color;

    &:before {
      content: "\e60a";
    }
  }

  .editableTimepicker {
    .p-dropdown {
      min-width: 70px;
      height: 40px;
      @include border-radius(0px);
      border: 1px solid $footer-bg;

      &:not(.p-disabled):hover {
        border-color: 1px solid $footer-bg;
      }
      /*&:not(.p-disabled).p-focus {
        border-color: $footer-bg;
      }*/
    }

    &.business-unit-dropdown {
      .p-dropdown {
        border: 1px solid $textarea-label-color;
        width: 200px;
        height: 43px;
      }
    }

    &.request-list-header-dropdown {
      .p-dropdown {
        border: 1px solid $textarea-label-color;
        width: 100%;
        height: 35px;
      }
    }

    &.request-list-filter-dropdown {
      .p-dropdown {
        border: 1px solid $textarea-label-color;
        width: 100%;
        height: 56px;
      }
    }

    &.mutual-fund-asset-group-dropdown {
      .p-dropdown {
        border: 1px solid $textarea-label-color;
        width: 40%;
        height: 38px;
      }
    }

    &.client-name-dropdown {
      .p-dropdown {
        width: 235px;
        border: 1px solid $textarea-label-color;
      }
      .p-dropdown-item {
        white-space: inherit;
      }
    }

    &.master-account-dropdown {
      .p-dropdown {
        border: 1px solid $textarea-label-color;
        min-width: 9.5em;
        height: 50px;

        .p-dropdown-label {
          padding: 1em;
        }
      }
    }
  }

  .time-errorHighlight {
    .p-dropdown {
      border: 1px solid $error-field-border !important;
    }
  }

}

.rule-list-filter-datepicker {
  .emtr-datepicker {
    height: 35px;

    .p-calendar {
      &.p-calendar-w-btn {
        .p-inputtext {
          width: 80% !important;
          height: 29px;
        }

        .p-datepicker-trigger.p-button {
          width: 1.2em !important;
        }
      }
    }

    .p-button-icon-only .p-button-icon-left {
      left: 20% !important;
    }
  }

  .emtr-calender {
    border: 1px solid #98A4AE;
  }
}

.p-datepicker-header {
  padding: 0 0.5rem;
  .p-datepicker-title {
    .p-datepicker-year, .p-datepicker-month {
      padding: 0 0.5rem;
    }
  } 
} 

.p-datepicker table td > span {
  width: 2rem;
  height: 2rem;
}

.rule-form-date-time-picker {
  .timePickerForm {
    .editableTimepicker {
      .p-dropdown {
        height: 46px;
      }
    }
    .emtr-dropdown {
      .sdps-dropdown__select {
        height: 46px;
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .rule-list-filter-datepicker {
    .emtr-datepicker {
      .p-calendar{
        min-width: 6.5rem;
        &.p-calendar-w-btn{
          .p-inputtext {
            width: 78% !important;
            height: 29px;
          }
          .p-datepicker-trigger.p-button{
            width: 0.8em !important;
            margin-right: 0.2rem;
          }
        }
      }
      .p-button-icon-only .p-button-icon-left {
        left: 25% !important;
      }
    }

  }

}
