@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  select::-ms-value {
    background: transparent;
    color: black;
  }

  .upload-buttons-alignment {
    margin: 0 !important;
  }

  .emtr-calender {
    &.request-list-filter-calender {
      width: 200px;
    }
  }

  .ui-table table {
    table-layout: auto;
  }

  .auth-agent-table-layout-fixed {
    .ui-table table {
      table-layout: fixed !important;
    }
  }
}
