.display-chart {
    .ui-progressbar {
        height: 12px;
    }
    .overview-title {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #9CAF86 !important;
            }
        }
    }
    .overview-Members {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #F3E9D2 !important;
            }
        }
    }
    .overview-Groups {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #D3B79B !important;
            }
        }
    }
    .overview-Emp {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #333333 !important;
            }
        }
    }
    .request-mismatch {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #707070 !important;
            }
        }
    }
    .request-action {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #E1DCAA !important;
            }
        }
    }
    .request-index {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #98A4AE !important;
            }
        }
    }
    .linked-FA {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #F9A03F !important;
            }
        }
    }
    .linked-SL {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #CFBFF7 !important;
            }
        }
    }
    .linked-NM {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #579228 !important;
            }
        }
    }
    .active-status {
        .ui-progressbar { 
            .ui-progressbar-value {
                background: #96CC55 !important;
            }
        }
    }
}