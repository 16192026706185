.common-data-range {
  .md-drppicker {
    .btn {
      background: #037DAE !important;
      color: #fff;
      padding: 20px 20px;
      line-height: 0;
      position: none;
      border-radius: 0;
      text-transform: none;
      font-weight: bold;
    }

    .clear {
      box-shadow: none;
      padding: 11px 20px;
      background: #fff;
      border: 1px solid #037DAE;
      color: #037DAE;
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .filtered-table .md-drppicker table tr th {
    border-bottom: 0 !important;
  }

  .filtered-table .md-drppicker table tr td {
    border-bottom: 0 !important;
  }

  .filtered-table .md-drppicker th {
    color: #000 !important;
    padding: 8px !important;
    border: 0 !important;
  }

  .filtered-table .md-drppicker table tr td.available {
    color: #333;
    font-weight: normal;
  }
}
