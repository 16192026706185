.subNavigation {
  background: $header-bg;
  border-top: 1px solid #fff;
  padding-left: 24px;

  .sdps-navigation--horizontal__item {
    &.sdps-is-active {
      .sdps-navigation--horizontal__link {
       background: #fff;
        color: $subnav-active;
        border-bottom: 4px solid $white !important;
      }

      .buttons {
        &.sub-navigation-button {
          background: white;
          border: none;
          color: #425563;
          padding: 0;
          font-size: 15px
        }
      }
    }

    .sdps-navigation--horizontal__link {
      color: white;
      font-size: 15px;
      padding: 16px 12px 13px 11px;
      line-height: 18px;
      text-decoration: none;
      &:hover {
        border-bottom: 4px solid $active-link;
      }
    }
  }
  .buttons {
    &.sub-navigation-button {
      background: #3C4B56;
      border: none;
      color: white;
      padding: 0;
      font-size: 15px
    }
  }
}

.demo-border-bottom {
  & :hover {
    border-bottom: 0 !important;
  }
}
