@import "global";

.status {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height:14px;
  @include border-radius(20px);
  vertical-align: middle;

  &.draft, &.pending {
    background: #E8AB3B;
  }

  &.active, &.complete {
    background: #7A9C49;
  }

  &.scheduled, &.processing, &.started, &.received {
    background: #007ad9;
  }

  &.expired, &.inactive, &.error, &.failed, &.error {
    background: #BF5549;
  }

  span {
    vertical-align:middle;
  }
}

.ruleGrpLinks {
  color: #037DAE !important;
  text-align: center;
}

.download-template {
  border: none;
}

.clientNameLinks {
  color: #037DAE;
  text-align: left;
}