.custom-Calendar {
  input {
    width: 100%;
  }

  .p-calendar {
    .p-datepicker {
      .p-datepicker-header {
        padding: 0 0.5rem;
        .p-datepicker-title {
          .p-datepicker-year, .p-datepicker-month {
            padding: 0 0.5rem;
          }
        } 
      } 
      // width: 50rem !important;
      display: flex;
      position: absolute;
      left: -500px !important;
      border: 1px solid #037DAE;
      border-radius: 0 !important;
      table td {
        font-weight: normal;
        padding:8px;
        border: none !important;

        &>a {
          padding: 2px !important;
        }

        &>span {
          padding: 2px !important;
        }
      }
    }

    .p-button {
      box-shadow: none;
      outline: none;
      background-color: transparent;
      color: #007ad9 !important;
      border: 1px solid #d8d8d8 !important;
      display: inline-block;
      width: 100%;
      font-weight: bold;
      text-align: center;
      border-radius: 0 !important;
      &.active {
        background-color: #007ad9;
        color: #fff !important;
      }

      &:enabled {
        &:active {
          color: #fff !important;
        }

        &:hover {
          background-color: $calendar-button-background-hover;
          color: #fff !important;
        }
      }
    }

    .calendar-submit-buttons {
      float: left;

      .calendar-buttons {
        height: 44px;
        padding: 10px;
      }
    }
  }
}

.requestlist-calendar {
  .custom-Calendar .p-calendar {
    .p-datepicker {
      left: 0px !important;
    }
  }
}