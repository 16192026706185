.file-upload {
    .file-section {
      background: #F7F7F7;
      border: 1px solid #D8D8D8;
      text-align: center;

      a {
        color: #037dae;
        display: inline-block;
      }

      .file-upload-btn {
        .p-fileupload-buttonbar {
          background: none;
          border: 0;
          padding: 0;

          .p-button:focus{
            box-shadow: none;
          }

          .p-fileupload-choose {
            border: 1px solid #037DAE !important;
            background: #fff;
            color: #037DAE;
            padding: 9px 20px;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;

            .pi-plus {
              font-size: 20px;

              &:before {
                content: "\e944";
              }
            }

            .p-button-text {
              padding: 0 1em 0 2em;
            }
          }
        }

        .p-fileupload-content {
          display: none;
        }
      }

      &.choose-file-border {
        .p-fileupload-buttonbar {
          .p-fileupload-choose {
            border: none !important;
          }
        }
      }

      .action-button {
        em {
          color: #e70d18;
          vertical-align: top;
          cursor: pointer;
        }
      }
    }

    .security-uploads-button {
      background: none !important;
      border: none !important;
      text-align: left !important;

      .p-fileupload, .p-fileupload-buttonbar {
        &:focus, .p-button.p-fileupload-choose.p-focus {
          box-shadow: none !important;
        }
      }
    }
  }
