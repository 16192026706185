/* You can add global styles to this file, and also import other style files */

@import "~@schwab/atomic.designsystem/styles/sdps.css";
@import "~@schwab/atomic.designsystem/styles/sdps-beta.css";
@import "~@schwab/atomic-fonts/style.css";
@import "~primeng/resources/themes/nova/theme.css";
@import "~primeng/resources/primeng.min.css";
@import "~primeicons/primeicons.css";
@import "assets/custom-css/variable";
@import "global";
@import "custom-class";
@import "sub-navigation";
@import "select-dropdown";
@import "coming-soon";
@import "custom-progressbar";
@import "file-upload";
@import "reports";
@import "projects/ems/shared/src/shared-styles";

@import "assets/styles/ems/ems-input";
@import "assets/styles/addlink/addlink-modal";
@import "assets/styles/addlink/addlink-input";
@import "assets/styles/addlink/addlink-common";
@import "assets/styles/addlink/upload";
@import "date-range";
@import "multiselect-dropdown";

@import "projects/ems/shared/src/assets/custom-css/data-table";

@import "assets/custom-css/ie_only";

@import "~@schwab/everest-css/dist/css/unified/index.css";


