@import "global";

.emtr-warning-box {
  &.global-timeout {
      .p-confirm-dialog .p-dialog-content {
         display: inline-block !important;
       }

      .p-confirm-dialog-message {
        display: block;
        margin: 0 !important;
        text-align: center;
        span:first-of-type {
          font-size: 55px !important;
        }
      }

      .border-top-line {
        border-top: 1px solid $disabled-color;
      }
    }

  .p-dialog {
    width: 33vw !important;

    .p-dialog-header {
      color: $subnav-heading;
      background: $white;
      padding: $font-2x;
      border: 0;
      border-bottom: 1px solid $table-record-border;

      .p-dialog-title {
        font-size: $font-bold;
        // word-wrap: break-word;
        word-break: break-word;
      }

      button {
        position: absolute;
        right: 15px;
        top: -40px;
        color: $white !important;

        .pi-times {
          &:before {
            content: "\e65b";
            font-size: $font-3x;
            font-family: 'Schwab-Icon-Font', serif !important;
            font-weight: 400;
          }
        }
      }
    }

    .p-dialog-content {
      border: none;
      padding-left: 20px;
      padding-top: 20px;
      align-items: normal;
      overflow: hidden;
      word-break: break-word;

      .p-confirm-dialog-icon {
        font-size: $font-4x !important;
        color:$error-icon-color;
        padding: 0;
        position: relative;
        top: 0;
      }
      .p-confirm-dialog-message {
        display: block;
        // margin: -1.9rem 0 0 3rem;
        word-wrap: break-word;
      }
    }

    .p-dialog-footer {
      padding: 0 20px 24px;
      text-align: center;
      border: none;
      .p-button {
        background: transparent;
        color: $button-border;
        @include border-radius(unset);

        .p-button-label {
          font-size: $font-normal;
          font-weight: bold;
          padding: 0.429em 1em;
        }
      }
    }

    .sdps-messaging__icon {
      padding: 75px 20px;
    }
  }
}

.sch-exclamation-octagon {
  &:before {
    color:$error-icon-color;
  }
}

