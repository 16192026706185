@import 'variable';

.addlink-required-field {
  font-weight: normal;
  position: relative;
  pointer-events: none;
  right: 5px;
  top: 3px;
  color: $error-field-border;
  line-height: 1;
  z-index: 100;
}
