@import "variable";
.employee-dropdown {
  &.employee-association-dropdown {
    .p-dropdown {
      min-width: 10.5em;
      padding: 0.7rem !important;
    }
    .emp-label {
      font-size: 12px;
      top: 1em !important;
      left: 0.8rem !important;

    }
    .p-float-label .p-dropdown-label {
      font-weight: normal !important;
    }
  }

  &.employee-trade-dropdown {
    .p-dropdown {
      padding: 1.1rem !important;
    }
  }

  .sdps-dropdown--lightGrey__select {
    border: 1px solid $textarea-label-color;
  }

  .sdps-dropdown__icon {
    top: 50%;
    left: 91%;
  }

  @media screen and (min-width: 1280px) and (max-width: 1500px) {
    .sdps-dropdown__icon {
      left: 89%;
    }
  }

  .sdps-dropdown--lightGrey__select:hover {
    border: 1px solid $textarea-label-color;
  }
}

.sdps-dropdown__select {
  height: 100%;
}
.preset-report {
  width: 100%;

  .table-record {
    width: 100%;
    padding-bottom: 10px;
    background: #f7f7f7;
    border: 1px solid #d8d8d8;
    margin-bottom: 10px;
  }

  .dropdown-check-list {
    width: 15%;

    .dropdown-list {
      height: auto;
      padding: 3px 10px !important;
    }
  }

  .report-list-report-details {
    width: 100%;
    margin-top: -12px;

    &.report-list-display {
      display: flex;
    }

    .report-list {

      .report-name-link {
        color: #037DAE;
      }

      .default-cursor {
        cursor: default;
        color: $subnav-heading;
      }

      .buttons{
        border: none;
      }
    }

    .report-list-compressed {
      width: 30%;
      border-right: 1px solid #98A4AE;
      float: left;
    }

    .report-list-compressed-table {
      float:left;
      width: 95%;
    }

    .report-details {
      float:right;
      width: 5%;
    }

    .disabled-excel-icon {
      color: #a6a6a6 !important;
    }

    .disabled-anchor {
      pointer-events: none;
    }

    .expand-report {
      border-left: 1px solid #98A4AE;
      border-bottom: 1px solid #98A4AE;
      border-top: 1px solid #98A4AE;
      color: #037DAE;
      position: absolute;
      right: -2px;
      background: white;
      padding: 5px 0 5px 5px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-bottomleft: 10px;
      -webkit-border-top-left-radius: 10px;
      -webkit-border-bottom-left-radius: 10px;
      cursor: pointer;
    }

    .report-details-expand {
      width: 70%;
      border-bottom: 1px solid #d8d8d8;
      border-right: 1px solid #d8d8d8;
      float: right;
    }

    .active-report-selected {
      color: #000 !important;
      font-weight: bold;
    }
  }
}

.select-report-type {
  .dropdown-width {
    width: 100% !important;
  }
  .p-inputtext {
    &.ng-dirty.ng-invalid {
      border: none
    }
  }

  .p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label{
    top: -.25rem !important;
  }

  .p-float-label {
    input {
      width: 100%;
      height: 25px;
      border: 0;
      transition: none;
      -webkit-appearance: none;

      &:focus ~ label {
        color: $iconColor;
      }
    }

    .p-element:disabled + label {
      opacity: 0.5;
    }
  }

  .report-textbox-border {
    border: 1px solid #98A4AE;
    padding-left: 0.5rem !important;

    &:focus-within {
      border: 1px solid $button-border;
    }

    &.invalidField {
      border-color: #a80000 !important;
      label {
        color: #a80000 !important
      }
    }
  }

  .emp-status {

    .report-status {
      display: inline-block;
    }
  }

  .input-disabled {
    cursor: not-allowed;
  }
}

.employee-status-text {
  padding-right: 0px !important;
}



