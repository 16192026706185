@import "variable";

.image-block {
    height: 100%;
    .info-container {
        height: 100%;

        .coming-soon-image {
          background: url("/assets/img/coming_soon.png");
          background-position: center;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
        }

        .coming-soon-txt {
          top: 50%;
          transform: translate(-10%, -50%);
          width: auto;
          min-width: 80px;
          left: 10%;
          background-color: $active-link;
          opacity: 0.7;
          color: $white;
          position: absolute;
          span {
              font-size: 18px;
          }
        }
      }
}
 /* Removing workbench-panel-header for addlink-section,only displaying addlink */
.addlink-section {
  .header-section-list {
    li {
      display: none;
    }

    .display-refresh {
      display: inline !important;
    }
  }
}
/* Removing workbench-panel-header for rules-section */
.rules-section {
  .header-section-list {
    li {
      display: none;
    }
  }
}
