@import "global";

.action-menu-dropdown {
    position: relative;
    display: initial;
    button {
        color: $button-border;
        background: $white;
        border: 1px solid $button-border;
        outline: none;
        font-size: $font-normal;
        &.active-menu {
            background: $button-border;
            color: $white;
        }
        .pi {
            font-size: $font-2x;
            vertical-align: middle;
        }
    }
    .menu-dropdown {
        position: absolute;
        z-index: 1;
        top:15px;
        right: 10px;
        border: 1px solid $footer-link;
        .p-menuitem {
            font-size: $font-normal;
            border-bottom: 1px solid $footer-bg;
            @include border-radius(0px);
            .p-menuitem-text, .p-menuitem-icon {
                color: $button-border;
            }
            .p-menuitem-link:hover {
                background: $button-border;
                .p-menuitem-text, .p-menuitem-icon {
                    color: $white;
                    font-size: $font-normal;
                }
            }
            .p-state-disabled {
                cursor: not-allowed !important;
            }
        }
      .p-menu {
        .p-menuitem-link {
          display: flex;
        }
      }
    }
}

.reportListMenu{
  .p-menu {
    width: 13.5em !important;
  }
}
