.securityLevelType{
  .p-treetable{
    .p-treetable-wrapper {
        overflow: scroll !important;
        max-height: 300px;
        overflow-x: hidden !important;
        border-left: 1px solid $table-record-border;
        border-bottom: 0;
        border-top: 0;
        padding: 0 25px;
    }
  }
}

.subNavHeading {
    padding: 0;
}

.sdps-modal__body {
    .treetable-outline {
        border: 1px solid $table-record-border;

        &:nth-child(2) {
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }
        .goto-top {
            border: 1px solid $table-record-border;
            border-top: 0;
            border-bottom: 0;
            padding: 15px;
        }
    }


}

body {

    .table-caption-header {
        .p-treetable {
            .p-treetable-header {
                border: 0;
                padding: 0;
            }
        }
    }

    .p-treetable {
        font-size: 16px;
        font-family: $font-family-regular;

        .p-treetable-wrapper {
          overflow: hidden !important;
        }

        .p-treetable-header {
            background: $white;
            border: 1px solid $table-record-border;
            padding: 15px;
            color: $table-record-border;
            font-weight: normal;
            text-align: left;

            .select-all {
                background: $table-record-background;
                border: 1px solid $table-record-border;
                margin-bottom: 15px;
                cursor: pointer;
                padding: 15px 20px;
            }

            .security-title {
                padding: 15px 20px;
                color: $table-th;
                border: 1px solid $table-record-border;
            }
        }

        .p-treetable-thead {
            font-family: $font-family-bold;
            border-bottom: 2px solid $button-border;
            font-weight: bold;

            &>tr {
                &>th {
                    border: 0;

                    background-color: transparent;
                    text-align: left;

                    &.p-sortable-column {
                        &:focus {
                            outline: 1px solid #000;
                            border-top: 1px solid #000;
                        }

                        &:hover {
                            background: transparent;
                        }

                        &.p-state-highlight {
                            color: $button-border;
                            background: transparent;
                        }
                    }
                }
            }
        }

        .p-sortable-column {
            &.p-state-highlight {
                .p-sortable-column-icon {
                    color: $button-border;
                }
            }
        }

        .p-treetable-tbody {
            border-bottom: 2px solid $button-border;

            &>tr {
                &:focus {
                    outline: 0;
                }

                &.p-state-highlight {
                    background-color: transparent;
                    color: $subnav-heading;
                }

                &:nth-child(even) {

                    background-color: $table-record-background;
                    color: $subnav-heading;
                }

                &>td {
                    border: 0;
                }
            }
        }
    }
}

.invalidField {
  border-color: $error-field-border !important;
}

.manageEmp{
  .p-treetable {
    .p-treetable-wrapper {
      overflow: visible !important;
    }
  }
  .p-menu {
    width: 14.5em !important;
  }
}


